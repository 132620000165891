import { motion, useSpring, useTransform } from "framer-motion"
import { useEffect } from "react";
import {useInView} from "react-intersection-observer";

const AnimatedNumber = ({ value, className, style }: { value: number, className: string, style: any }) => {
  const { ref, inView, entry } = useInView();
  let spring = useSpring(value, { mass: 0.1, stiffness: 1, damping: 1 });
  let display = useTransform(spring, (current) =>
      Math.round(current).toLocaleString()
  );

  useEffect(() => {
    if (inView) spring.set(value);
  }, [spring, value, inView]);


  return <motion.span ref={ref} className={className} style={style}>{display}</motion.span>;
}

export default AnimatedNumber
